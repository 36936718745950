<template>
  <section class="main-section bg-white">
    <main-section-header title="센서 등록" />
    <div class="container">
      <subtitle subtitle="센서 정보" />
      <ul class="register-list">
        <detail-list-row
          :is-register="true"
          v-for="(info, index) in sensorInfo"
          :key="index"
          :info="info"
          :inputKey="info.key"
          :input-value="sensorData[info.key]"
          :maxlength="info.maxlength"
          :placeholder="info.placeholder"
          @saveChangeValue="onSaveInputValue"
        >
          <span slot="label" class="label" :class="{ 'label-required': info.isRequired }">
            {{ info.label }}
          </span>
        </detail-list-row>
      </ul>
      <list-bottom-button-box>
        <button-basic slot="button" color="gray-border-1" :width="120" @click="onMoveSensorList">취소</button-basic>
        <button-basic
          slot="button"
          color="primary"
          :width="120"
          @click="onSaveSensor"
          :disabled="!sensorData.modelName"
        >
          등록
        </button-basic>
      </list-bottom-button-box>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import SensorRegisterData from './SensorRegisterData.json';

export default {
  name: 'SensorRegister',
  props: [],
  data() {
    return {
      sensorData: {
        modelName: '',
        serialNumber: null,
        description: '',
      },
      sensorInfo: SensorRegisterData,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('manufacturerSensors', ['POST_MANUFACTURER_SENSOR']),
    ...mapActions('factorySensors', ['POST_FACTORY_SENSOR']),
    onSaveInputValue(inputKey, changedValue) {
      this.sensorData[inputKey] = changedValue;
    },
    async onSaveSensor() {
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_SENSOR(this.sensorData);
          break;
        case 'F':
          await this.POST_FACTORY_SENSOR(this.sensorData);
          break;
      }
      this.onMoveSensorList();
    },
    onMoveSensorList() {
      this.$router.push(`/sensors`).catch((err) => err);
    },
  },
  components: { MainSectionHeader, Subtitle, ListBottomButtonBox, DetailListRow },
};
</script>

<style scoped lang="scss">
@import 'SensorRegister';
</style>
