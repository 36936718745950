var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white" },
    [
      _c("main-section-header", { attrs: { title: "센서 등록" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "센서 정보" } }),
          _c(
            "ul",
            { staticClass: "register-list" },
            _vm._l(_vm.sensorInfo, function (info, index) {
              return _c(
                "detail-list-row",
                {
                  key: index,
                  attrs: {
                    "is-register": true,
                    info: info,
                    inputKey: info.key,
                    "input-value": _vm.sensorData[info.key],
                    maxlength: info.maxlength,
                    placeholder: info.placeholder,
                  },
                  on: { saveChangeValue: _vm.onSaveInputValue },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": info.isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_vm._v(" " + _vm._s(info.label) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "list-bottom-button-box",
            [
              _c(
                "button-basic",
                {
                  attrs: { slot: "button", color: "gray-border-1", width: 120 },
                  on: { click: _vm.onMoveSensorList },
                  slot: "button",
                },
                [_vm._v("취소")]
              ),
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "primary",
                    width: 120,
                    disabled: !_vm.sensorData.modelName,
                  },
                  on: { click: _vm.onSaveSensor },
                  slot: "button",
                },
                [_vm._v(" 등록 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }